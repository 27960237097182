import React from "react"
import _ from "lodash"

export default class Icon extends React.Component {
  render() {
    let icon = _.get(this.props, "icon", null)
    return (
      <svg
        className='icon'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'>
        {icon === "dev" ? (
          <path d='M6.435 9.444c-0.208-0.155-0.416-0.233-0.624-0.233h-0.934v5.597h0.935c0.208 0 0.416-0.078 0.624-0.233s0.312-0.388 0.312-0.7v-3.731c-0.001-0.311-0.105-0.544-0.312-0.7zM21.649 0h-19.296c-1.296 0-2.349 1.049-2.352 2.346v19.307c0.003 1.297 1.055 2.346 2.352 2.346h19.296c1.297 0 2.349-1.049 2.352-2.346v-19.307c-0.003-1.297-1.055-2.346-2.352-2.346zM8.261 13.885c0 1.008-0.622 2.534-2.591 2.531h-2.486v-8.864h2.538c1.899 0 2.537 1.525 2.538 2.533l0.001 3.8zM13.655 9.136h-2.854v2.058h1.745v1.584h-1.745v2.058h2.855v1.584h-3.331c-0.598 0.016-1.095-0.457-1.11-1.055v-6.702c-0.014-0.597 0.459-1.093 1.056-1.108h3.385l-0.001 1.581zM19.207 15.312c-0.707 1.647-1.974 1.319-2.541 0l-2.064-7.757h1.745l1.592 6.092 1.584-6.092h1.745l-2.060 7.757z' />
        ) : icon === "pdf" ? (
          <path d='M15.1 2H24v20L15.1 2zM8.9 2H0v20L8.9 2zM12 9.4L17.6 22h-3.8l-1.6-4H8.1L12 9.4z' />
        ) : icon === "facebook" ? (
          <path d='M23.998 12c0-6.628-5.372-12-11.999-12C5.372 0 0 5.372 0 12c0 5.988 4.388 10.952 10.124 11.852v-8.384H7.078v-3.469h3.046V9.356c0-3.008 1.792-4.669 4.532-4.669 1.313 0 2.686.234 2.686.234v2.953H15.83c-1.49 0-1.955.925-1.955 1.874V12h3.328l-.532 3.469h-2.796v8.384c5.736-.9 10.124-5.864 10.124-11.853z' />
        ) : icon === "github" ? (
          <path d='M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12' />
        ) : icon === "email" ? (
          <g
            xmlns='http://www.w3.org/2000/svg'
            transform='matrix(0.050632916 0 0 0.050632916 0 0)'>
            <path
              d='M437.5 59.3L36.5 59.3C 16.4 59.3 0 75.7 0 95.8L0 95.8L0 378.2C 0 398.30002 16.4 414.7 36.5 414.7L36.5 414.7L437.5 414.7C 457.6 414.7 474 398.30002 474 378.2L474 378.2L474 95.8C 474 75.7 457.6 59.3 437.5 59.3zM432.2 86.3L239.5 251.1L46.8 86.3L432.2 86.3zM447 378.2C 447 383.40002 442.7 387.7 437.5 387.7L437.5 387.7L36.5 387.7C 31.3 387.7 27 383.40002 27 378.2L27 378.2L27 104.9L230.7 279.1C 230.8 279.2 231 279.30002 231.09999 279.4C 231.2 279.5 231.4 279.6 231.49998 279.69998C 231.79999 279.9 231.99998 280.09998 232.29999 280.19998C 232.4 280.3 232.49998 280.3 232.59999 280.4C 232.99998 280.6 233.4 280.8 233.79999 281C 233.9 281 233.99998 281.1 234.09999 281.1C 234.4 281.2 234.7 281.4 235.09999 281.5C 235.2 281.5 235.4 281.6 235.49998 281.6C 235.79999 281.7 236.09999 281.80002 236.39998 281.80002C 236.49998 281.80002 236.69998 281.90002 236.79997 281.90002C 237.09998 282.00003 237.49997 282.00003 237.79997 282.10004C 237.89998 282.10004 237.99997 282.10004 238.09998 282.10004C 238.49997 282.10004 238.99997 282.20004 239.39998 282.20004C 239.79997 282.20004 240.29997 282.20004 240.69998 282.10004C 240.79999 282.10004 240.89998 282.10004 240.99998 282.10004C 241.29999 282.10004 241.69998 282.00003 241.99998 281.90002C 242.09999 281.90002 242.29999 281.80002 242.39998 281.80002C 242.69998 281.7 242.99998 281.6 243.29997 281.6C 243.39998 281.6 243.59998 281.5 243.69997 281.5C 243.99997 281.4 244.29997 281.3 244.69997 281.1C 244.79997 281.1 244.89996 281 244.99997 281C 245.39996 280.8 245.79997 280.6 246.19997 280.4C 246.29997 280.3 246.39996 280.3 246.49997 280.19998C 246.79997 279.99997 246.99997 279.9 247.29997 279.69998C 247.39998 279.59998 247.59998 279.49997 247.69997 279.4C 247.79997 279.3 247.99997 279.19998 248.09996 279.1L248.09996 279.1L447 109.2L447 378.2z'
              stroke='none'
              fill='#000000'
              fill-rule='nonzero'
            />
          </g>
        ) : icon === "instagram" ? (
          <path d='M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913a5.885 5.885 0 001.384 2.126A5.868 5.868 0 004.14 23.37c.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558a5.898 5.898 0 002.126-1.384 5.86 5.86 0 001.384-2.126c.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913a5.89 5.89 0 00-1.384-2.126A5.847 5.847 0 0019.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227a3.81 3.81 0 01-.899 1.382 3.744 3.744 0 01-1.38.896c-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421a3.716 3.716 0 01-1.379-.899 3.644 3.644 0 01-.9-1.38c-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678a6.162 6.162 0 100 12.324 6.162 6.162 0 100-12.324zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405a1.441 1.441 0 01-2.88 0 1.44 1.44 0 012.88 0z' />
        ) : icon === "linkedin" ? (
          <path d='M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 01-2.063-2.065 2.064 2.064 0 112.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z' />
        ) : icon === "university" ? (
          <path d='M4,10v7h3v-7H4z M10,10v7h3v-7H10z M2,22h19v-3H2V22z M16,10v7h3v-7H16z M11.5,1L2,6v2h19V6L11.5,1z' />
        ) : icon === "reddit" ? (
          <path d='M12 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0zm5.01 4.744c.688 0 1.25.561 1.25 1.249a1.25 1.25 0 0 1-2.498.056l-2.597-.547-.8 3.747c1.824.07 3.48.632 4.674 1.488.308-.309.73-.491 1.207-.491.968 0 1.754.786 1.754 1.754 0 .716-.435 1.333-1.01 1.614a3.111 3.111 0 0 1 .042.52c0 2.694-3.13 4.87-7.004 4.87-3.874 0-7.004-2.176-7.004-4.87 0-.183.015-.366.043-.534A1.748 1.748 0 0 1 4.028 12c0-.968.786-1.754 1.754-1.754.463 0 .898.196 1.207.49 1.207-.883 2.878-1.43 4.744-1.487l.885-4.182a.342.342 0 0 1 .14-.197.35.35 0 0 1 .238-.042l2.906.617a1.214 1.214 0 0 1 1.108-.701zM9.25 12C8.561 12 8 12.562 8 13.25c0 .687.561 1.248 1.25 1.248.687 0 1.248-.561 1.248-1.249 0-.688-.561-1.249-1.249-1.249zm5.5 0c-.687 0-1.248.561-1.248 1.25 0 .687.561 1.248 1.249 1.248.688 0 1.249-.561 1.249-1.249 0-.687-.562-1.249-1.25-1.249zm-5.466 3.99a.327.327 0 0 0-.231.094.33.33 0 0 0 0 .463c.842.842 2.484.913 2.961.913.477 0 2.105-.056 2.961-.913a.361.361 0 0 0 .029-.463.33.33 0 0 0-.464 0c-.547.533-1.684.73-2.512.73-.828 0-1.979-.196-2.512-.73a.326.326 0 0 0-.232-.095z' />
        ) : icon === "twitter" ? (
          <path d='M23.954 4.569a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.061a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.937 4.937 0 004.604 3.417 9.868 9.868 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 002.46-2.548l-.047-.02z' />
        ) : icon === "youtube" ? (
          <path d='M23.495 6.205a3.007 3.007 0 00-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 00.527 6.205a31.247 31.247 0 00-.522 5.805 31.247 31.247 0 00.522 5.783 3.007 3.007 0 002.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 002.088-2.088 31.247 31.247 0 00.5-5.783 31.247 31.247 0 00-.5-5.805zM9.609 15.601V8.408l6.264 3.602z' />
        ) : (
          icon === "vimeo" && (
            <path d='M23.977 6.416c-.105 2.338-1.739 5.543-4.894 9.609-3.268 4.247-6.026 6.37-8.29 6.37-1.409 0-2.578-1.294-3.553-3.881L5.322 11.4C4.603 8.816 3.834 7.522 3.01 7.522c-.179 0-.806.378-1.881 1.132L0 7.197a315.065 315.065 0 003.501-3.128C5.08 2.701 6.266 1.984 7.055 1.91c1.867-.18 3.016 1.1 3.447 3.838.465 2.953.789 4.789.971 5.507.539 2.45 1.131 3.674 1.776 3.674.502 0 1.256-.796 2.265-2.385 1.004-1.589 1.54-2.797 1.612-3.628.144-1.371-.395-2.061-1.614-2.061-.574 0-1.167.121-1.777.391 1.186-3.868 3.434-5.757 6.762-5.637 2.473.06 3.628 1.664 3.493 4.797l-.013.01z' />
          )
        )}
      </svg>
    )
  }
}
